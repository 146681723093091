
import fetch from './FetchInterceptor';

export const  getDataByParams = (basePath: string, params?: { [param: string]: string | string[]; }) => {
    return fetch.get(`${basePath}`, {params: params}).then(response => response.data);
}

export const  getAll = (basePath: string, page: number = -1, perPage: number = -1) => {
    return getDataByParams(basePath, {page: `${page}`, perPage: `${perPage}`});
}

export const  getById = (basePath: string, id: number) => {
    return fetch.get(`${basePath}/${id}`).then(response => response.data);
}

export const  post = (basePath: string, item: any) => {
    return fetch.post(basePath, item).then(response => response.data);
}

export const  postWithParams = (basePath: string, params: { [param: string]: string | string[]; }, item?:any| null) => {
    return fetch.post(`${basePath}`, item?item:{}, {params: params}).then(response => response.data);
}

export const  postWithAdditionalPath = (basePath: string, item?: any| null) => {
    return fetch.post(basePath, item?item:{}).then(response => response.data);
}
export const  put = (basePath: string, item: any) => {
    if (!item || !item.id) {
        console.warn('Put method needs an id into the item', item);
    }
    return fetch.put(basePath, item).then(response => response.data);
}
export const  deleted = (basePath: string, item:any) => {
        return fetch.delete(basePath, item).then(response => response.data);
}
export const  upsert = (basePath: string, item: any) => {
    if (item.id) {
        return put(basePath, item);
    }
    return post(basePath, item);
}


export const  search = (basePath: string, body: any | null, params?: { [param: string]: string; }) => {
    let url = `${basePath}/search`;
    if (params) {
        let query = '';
        for (let param in params) {
            if (query === '') {
                query = `${param}=${params[param]}`;
            } else {
                query += `&${param}=${params[param]}`;
            }
        }
        if (query !== '') {
            url = `${url}?${query}`;
        }
    }
    return post(url, body);
}

export const  searchWithParams = (body: any, pageNumber: number, pageSize: number) => {
    return search(body, {page: `${pageNumber}`, perPage: `${pageSize}`});
}

export const postList = (basePath:string, items: any) => {
    return fetch.post("", items);
}

export const pageFromList = (data: any[], pageNumber: number = -1, pageSize: number = -1) => {
    let totalEntityCount = data.length;
    let allData = pageNumber <= 0 || pageSize <= 0;
    let pageCount = allData ? 1 : ((totalEntityCount / pageSize) + Math.ceil((totalEntityCount % pageSize) / pageSize));
    let start = (pageNumber - 1) * pageSize;
    let entities = data.slice(allData ? 0 : start, allData ? totalEntityCount : Math.min(pageNumber * pageSize, totalEntityCount));
    let pagination = {
        page: pageNumber,
        pageSize: pageSize,
        totalEntityCount: totalEntityCount,
        pageCount: pageCount,
        entities: entities
    };
    return Promise.resolve(pagination);
}
